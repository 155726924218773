<template lang="pug">
    section#projetosDestaques
        .wrapper
            h2 Projetos em Destaque
            //- p.sub Lorem ipsum
            #projetos
                ul.navProjetos
                    li(v-for="item, key, index in projetos", @mouseover="changeProjeto(index)" :class="{'ativo': index == projetoAtivo}")
                        router-link(:to="{ name: 'Pagina', params: { slug: getSlug(item) } }")
                            p {{ item.title }}
                            SvgIcon(data="@svgs/seta-ippuc.svg")

                ul.projetos
                    li.projeto(v-for="item, key, index in projetos" :class="{'ativo': index == projetoAtivo}")
                        button(@click="shareContent(item.title.pt, getCompleteLink(item))")
                            FontAwesomeIcon(:icon="icons.faShareNodes")
                        router-link(:to="{ name: 'Pagina', params: { slug: getSlug(item) } }")
                            Imagem(:contents="item.thumbnail")
                            div.texto
                                p {{ item.description }}
                                span Ler mais

</template>

<script>
import { components, props, shareContent, getCompleteLink } from '@/mixins/components'

import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'section-projetos-destaques',
  props,
  components,
  data () {
    return {
      icons: {
        faShareNodes
      },
      projetoAtivo: 0,
      projetos: {}
    }
  },
  mounted () {
    this.contents.browsers.forEach(item => {
      this.loadProject(item.slug)
    })
  },
  methods: {
    changeProjeto (index) {
      this.projetoAtivo = index
    },
    loadProject (slug) {
      this.$axios
        .get(`paginas/${slug}`)
        .then(response => this.$set(this.projetos, slug, response.data))
    },
    getSlug (projeto) {
      return projeto.slugs.find(item => item.locale == 'pt').slug
    },
    getBackground (projeto) {
      return {
        backgroundImage: `url(${projeto.thumbnail.desktop.src})`
      }
    },
    shareContent,
    getCompleteLink
  }
}
</script>

<style lang="stylus" scoped src="./ProjetosDestaques.styl"></style>
